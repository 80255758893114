
import { Tag } from 'apprise-frontend-tags/tag/model';
import { useRouting } from 'apprise-ui/utils/routing';
import { useContext } from 'react';
import { allCategoriesTab } from '../list';
import { TagCategory, useCategoryModel } from './model';
import { CategoryRoutingContext, CategoryRoutingState } from './provider';




export const tagSystemRoute = "/tags"
export const categoryRoute = `${tagSystemRoute}/category`

export const downloadParam = 'donwload'

export const useCategoryRouting = () => {

    const { routeTo, query } = useRouting()

    const categorystate = useContext(CategoryRoutingContext)

    const categories = useCategoryModel()

    const self = {

        categoryListRoute: () => `${tagSystemRoute}?tab=${allCategoriesTab}`

        ,

        categoryDetailRoute: (id?: string) => id ? `${categoryRoute}/${id}` : self.newCategoryRoute()

        ,

        innerDetailRoute: (id?: string) => `${self.categoryDetailRoute(id)}${query()}`
    

        ,

        tagsInCategoryRoute: (id: string) => `${self.categoryDetailRoute(id)}/?tab=${allCategoriesTab}`


        ,

        tagInCategoryRoute: (cat: string, id: string) => `${self.categoryDetailRoute(cat)}/tag/${id}`

        ,

        newCategoryRoute: () => `${categoryRoute}/new`

        ,

        routeToCategory: (category: string | TagCategory) => routeTo(`${self.categoryDetailRoute(typeof category === 'string' ? category : category.id)}?${query()}`)

        ,

        routeToInnerCategory: (category: string | TagCategory) => `${self.routeToCategory(category)}?${query()}`

        ,

        routeToNewCategory: (directives: Partial<CategoryRoutingState> = {}) => Promise.resolve(categorystate.reset(directives)).then(() => routeTo(self.newCategoryRoute())) 

        ,

        routeToTagCategory: (category: string | TagCategory, tag: string| Tag) => routeTo(self.tagInCategoryRoute(typeof category === 'string' ? category : category.id, typeof tag === 'string' ? tag : tag.id))

        ,

        routeToCategoryList: () => routeTo(self.categoryListRoute())

        ,

        nextCategory: () => categorystate.get().nextCategory ?? categories.newCategory(undefined!)


    }

    return self

}

