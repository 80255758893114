
import { CategoryReference, TagCategory } from 'apprise-frontend-tags/category/model'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { MultiSelectProps, PolySelectBox, PolySelectProps, SingleSelectProps } from 'apprise-ui/selectbox/selectbox'
import { useMemo } from 'react'
import { TagLabel } from './label'
import { Tag, TagReference, useTagModel } from './model'
import { useTagStore } from './store'


// a SelectBox with tag-based options.
type TagBoxProps = Partial<{

     // standard use case: select among tags in a given category.
    category: CategoryReference | TagCategory

    // standard use case: select all tags of a given type.
    type: string,

    // other use cases: 
    // 1. select a few tags in a category.
    // 2. select tags across more categories.
    // 3. select among all tags, 
    // 4. externally-controlled tags, 
    tags: (Tag | TagReference) []

    // another use case: 5. add additional tag-like options  to the list (eg. 'all', 'default', etc.).
    // just give them base props: id, name and/or code, and descriptions.
    // alway listed first, don't partake of sorting.
    extraOptions: (Partial<Tag>)[]
    
    // label prototype for tag options, possibly tag-specific.
    // note: if 'displayMode' is 'code', then options are ordered by code.
    labelPrototype: JSX.Element | ((tag: Tag) => JSX.Element
)
}>

export type SingleTagBoxProps = Omit<SingleSelectProps<TagReference>, 'options'> & TagBoxProps

export const TagRefBox = (props: SingleTagBoxProps) => <PolyTagRefBox {...props} />


export type MultiTagBoxProps = Omit<MultiSelectProps<TagReference>, 'options'> & TagBoxProps

export const MultiTagRefBox = (props: MultiTagBoxProps) => <PolyTagRefBox multi {...props} />

export const PolyTagRefBox = (props: PolySelectProps<TagReference> & TagBoxProps) => {

    const store = useTagStore()
    const model = useTagModel()
    const catstore = useCategoryStore()
 
    const { type, category, tags, labelPrototype, extraOptions=[], info, defaultValue, ...rest } = props

    const normalisedPrototype = typeof labelPrototype === 'function' ? labelPrototype : (() => labelPrototype as JSX.Element)

    const comparator = normalisedPrototype(model.newTag())?.props.displayMode === 'name' ? model.comparator: model.codeComparator


    const options = useMemo(()=> {

        const normalisedTags = tags?.map(t => typeof t === 'string' ? store.safeLookupTag(t) : t)
        const normalisedCategory = typeof category === 'string' ? category : category?.id


        const extraOptionsAsTags = extraOptions.map(o => ({...model.newTag(), ...o})).sort(comparator)   // completes partial tags to ensure they can be used as lbls 

        const options = normalisedTags ?? (normalisedCategory ? store.allTagsOfCategory(normalisedCategory) : type ? store.allTagsOf(type) : [])

         const sortedOptions = [...options].sort(comparator)

        const allOptions = extraOptionsAsTags.concat(sortedOptions)
        
        return allOptions.reduce(  (acc,tag) => ({...acc, [tag.id] : tag}) , {} as Record<TagReference,Tag>)

    //eslint-disable-next-line
    }, [category,tags, extraOptions])

    props.debug && console.log({tags, options, children: rest.children})



    const fullCategory =  typeof category === 'string' ? catstore.safeLookupCategory(category) : category

    const infoOrDefault = {
        label: fullCategory?.properties.field.title ?? fullCategory?.name,
        msg: fullCategory?.properties.field.message,
        help: fullCategory?.properties.field.help ?? fullCategory?.description,
        
        ...info
    }

    const defaultValueOrFallback =  defaultValue ?? fullCategory?.properties.field.default

    return <PolySelectBox
        options={Object.keys(options)}
        info={infoOrDefault}
        defaultValue={defaultValueOrFallback}
        textOf={t => model.textOf(options[t]).join(' ')}
        render={t => <TagLabel bare noIcon tag={options[t]} {...normalisedPrototype(options[t])?.props} />}
        {...rest} />
}