import { Tag } from "antd"
import { useT } from 'apprise-frontend-core/intl/language'
import { Multilang, useMultilang } from 'apprise-frontend-core/intl/multilang'
import { utils } from 'apprise-frontend-core/utils/common'
import { Component } from 'apprise-ui/component/component'
import { classname, Debugged, Disabled, Styled } from 'apprise-ui/component/model'
import { Link } from 'apprise-ui/link/link'
import { Linked } from 'apprise-ui/link/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { Tipped } from 'apprise-ui/tooltip/model'
import { Tip } from 'apprise-ui/tooltip/tip'
import { IconInactive, IconReadonly } from 'apprise-ui/utils/icons'
import * as React from 'react'
import {TruncatedList} from 'react-truncate-list'
import { GenericIcon, UnknownIcon } from '../utils/icons'
import "./styles.scss"
import "react-truncate-list/dist/styles.css"

/*
  An inlined representation of some concept, typically a domain object. 
  Includes the following elements, in left-to-right order:

  - an optional icon, typically an illustration of the object type.
  - some identifying title, typically the object name.
  - zero or more visual decorations, typically icons, that highlight distinguished object properties. 
  - zero or more quiet decorations, again typically icones, which are visible only on hover. 

  > icons and decorations can be forcefully hidden (noIcon, noDecorations), typically to override defaults introduced by
  extensions.

  > optionally, may be rendered as a tag.

  > may be Styled. Icon and title may be independently styled with iconStyle and titleStyle.

  > may be Disabled, Linked and Tipped. 
  
  > may be Readonly and adds a <IconReadonly> to the decorations.

  > if highlighted, renders the title in the primary color.

  > if inactive or protected, it automatically adds <IconInactive> and <IconProtected> to the decorations.

*/

export type LabelProps = Debugged & Styled & Tipped & Linked & Disabled & Readonly & Partial<{

    // indicates the label shows missing information, for consistent visuals.
    unknown: boolean | Multilang | string | React.ReactNode

    title: React.ReactNode | Multilang
    titleStyle: React.CSSProperties

    noIcon: boolean
    icon: JSX.Element
    iconStyle: React.CSSProperties

    fill: React.CSSProperties['color']
    fillNegative: true

    decorations: React.ReactNode[]
    quietDecorations: React.ReactNode[]
    noDecorations: boolean

    mode: 'normal' | 'tag'

    bare: boolean

    highlighted: boolean
    inactive: boolean


}>


// shows a placeholder thats stands for lack of information.
export const UnknownLabel = (props: LabelProps) => {

    const t = useT()

    const { unknown = true, title = unknown === true ? t("ui.unknown") : unknown, tip, inactive, bare=true, ...rest } = props

    // disabled looks are imposed and unknown is unset to avoid loops.
    // other props can be customised and some things have ready-made defaults.

    return <Label style={{opacity:.4}} tip={tip} bare={bare} inactive={false} icon={<UnknownIcon />} title={title} {...rest} unknown={false} />

}



export const Label = (props: Partial<LabelProps>) => {

    const ml = useMultilang()

    const {
        unknown,
        fill,
        title,
        mode ='normal', 
        fillNegative,
        bare,
        className,
        icon = <GenericIcon /> as JSX.Element,
        decorations = [],
        quietDecorations = [],
        readonly,
        noReadonly,
        inactive,
        highlighted,
        tip, noTip, tipClassName, tipDelay, tipType, tipWidth, tipPlacement,
        linkTarget, linkTo, noLink, linkOn,

        ...rest

    } = props


    const tipProps = { tip, noTip, tipClassName, tipDelay, tipType, tipWidth, tipPlacement }
    const linkProps = { linkTarget, linkTo, noLink, linkOn }

    // if there's not text to this label, then render nothing, unless there's a specific message for the 'unknown' case.
    if (title === undefined)
        return unknown ? <UnknownLabel  {...props} /> : null

    // if the underlying concept is 'inactive', render it as unknown but add a distinguished icon.
    // by default, make it not bare so that links can be followed to re-activate.
    if (inactive)
        return <UnknownLabel {...props}  bare={props.bare ?? false} decorations={[<IconInactive noTip={noTip} />, ... props.decorations ?? []]} />

    const classes: (string| undefined)[] = [className]

    const autodecorations: React.ReactNode[] = []

    if (mode === 'tag')
        classes.push('label-tag')

    if (fill)
        classes.push(classname('label-filled', fillNegative || 'fill-dark'))

    if (highlighted)
        classes.push("label-highlighted")

    if (readonly && !noReadonly)
        autodecorations.unshift(<IconReadonly readonly={readonly} noReadonly={noReadonly} noTip={noTip} />)

    const alldecorations = [...autodecorations, ...decorations]

    const content = <div style={{ background: fill ?? 'inherit' }}  className="apprise-row">
        {props.noIcon || <span className='label-icon' style={props.iconStyle} >{icon}</span>}
        <Link {...linkProps} noLink={bare || noLink}>
            <Tip {...tipProps} noTip={noTip}>
                <span className='label-title' style={props.titleStyle}>
                    {ml.test(title) ? ml.l(title) : title}
                </span>
            </Tip>
        </Link>
        {bare || props.noDecorations || alldecorations.length === 0 || alldecorations.map((d, i) => <div key={i} className="label-decoration">{d}</div>)}
        {bare || mode==='tag' || props.noDecorations || quietDecorations.length === 0 || quietDecorations.map((d, i) => <div key={i} className="label-option">{d}</div>)}
    </div>

    // force no tip and no link, as we've moved it to the title only. 
    return <Component name="label" className={classname(...classes)} {...rest} noTip noLink  >
        {mode === 'tag' ? <Tag style={{ background: fill, borderColor: fill }}>{content}</Tag> : content}
    </Component>
}


// aligns label children, propagates props to children, and handles overflow.
export type LabelRowProps = LabelProps & {

    children: (JSX.Element | false | undefined) | (JSX.Element | false | undefined)[]
    noTruncate?: boolean

}

export const LabelRow = (props: LabelRowProps) => {

    const { children: oneormore, noTruncate, style, className, ...rest } = props

    const children = utils().arrayOf(oneormore)
    
    const mappedChildren = utils().elementsIn(children).map((c, i) => React.cloneElement(c, { key: i, ...rest }))

    if (noTruncate)
        return <div style={style} className={classname("label-row", "no-truncate", className)}>{mappedChildren}</div>

    const onTruncate = ({ hiddenItemsCount }) => {

        return <Tip tipClassName="label-row-overflow" tip={children.slice(children.length - hiddenItemsCount)}>
           <span style={{marginLeft:3}}>{`+${hiddenItemsCount}`}</span>
        </Tip>
    }

    return <TruncatedList style={style} className={classname("label-row", className)} renderTruncator={onTruncate}>
        {mappedChildren}
    </TruncatedList>


}