import { useMode } from 'apprise-frontend-core/config/api';
import { useT } from 'apprise-frontend-core/intl/language';
import { Optional, utils } from 'apprise-frontend-core/utils/common';
import { useEventBus } from 'apprise-frontend-events/bus';
import { useAccounts } from 'apprise-frontend-iam/account/accounts';
import { Account } from 'apprise-frontend-iam/account/model';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import { useLogin } from 'apprise-frontend-iam/login/logged';
import { tenantPlural, tenantSingular } from 'apprise-frontend-iam/tenant/constants';
import { Tenant } from 'apprise-frontend-iam/tenant/model';
import { useTenantStore } from 'apprise-frontend-iam/tenant/store';
import { useCrud } from 'apprise-ui/utils/crudtask';
import { useFeedback } from 'apprise-ui/utils/feedback';
import { useContext } from 'react';
import { useUserCalls } from './calls';
import { userLogoutTopic, userPlural, userSingular } from './constants';
import { UserChangeEvent } from './events';
import { User, UserReference, useUnknownUser } from './model';
import { useUserPermissionModules } from './modules';
import { UserCacheContext } from './provider';


export const useUserStore = () => {

    const state = useContext(UserCacheContext)

    const t = useT()

    const fb = useFeedback()

    const crud = useCrud({ singular: userSingular, plural: userPlural })
    const tenantcrud = useCrud({ singular: tenantSingular, plural: tenantPlural })

    const calls = useUserCalls()

    const login = useLogin()
    const accounts = useAccounts()
    const oracle = useTenancyOracle()
    const tenantstore = useTenantStore()

    const permissionModules = useUserPermissionModules()

    const bus = useEventBus()

    const unknownUser = useUnknownUser()


    const mode = useMode()

    const internalAccountRemove = async (user: User, accountIncontext?: Account) => {

        if (login.isAuthenticated()) {

            // fetch account if we don't have it in context, eg. in bulk ops. 
            const account = accountIncontext ?? await accounts.fetchAccount(user)

            // if we provably have a remote account, remove it  and fail-fast because we can't retry later.
            if (!['none', 'unknown'].includes(account.state))
                await accounts.removeAccountQuietly(user.username)

        }

    }

    const internalRemove = async (user: User, accountIncontext?: Account) => {

        internalAccountRemove(user, accountIncontext)

        // if this fails, the user will be without account, but we can still try to remove it later.
        await calls.delete(user.username)

    }


    const internalAccountSuspendMany = async (users: User[]) => {
        if (login.isAuthenticated()) {

            await accounts.suspendManyAccountsQuietly(users)

        }
    }


    const saveQuietly = async (user: User) => {

        const isNew = !user.lifecycle.created

        const userWithDefaultPermission = (user: User) => ({ ...user, permissions: user.permissions.length > 0 ? user.permissions : permissionModules.initial(user) })

        const saved = isNew ? await calls.add(userWithDefaultPermission(user)) : await calls.update(user)

        state.set(s => s.all = isNew ? [saved, ...s.all] : s.all.map(t => t.username === saved.username ? saved : t))

        return saved

    }


    const saveManyQuietly = async (users: User[]) => {

        const saved = await calls.updateMany(users)

        state.set(s => s.all = s.all.map(t => {

            const idx = saved.findIndex(s => s.username === t.username)

            return idx >= 0 ? saved[idx] : t

        }))

        return saved

    }


    const self = {


        all: () => state.get().all

        ,

        lookup: (username: Optional<UserReference>) => username ? [...state.get().admins, ...self.all()].find(u => u.username === username) : undefined

        ,

        safeLookup: (username: Optional<UserReference>) => self.lookup(username) ?? unknownUser(username)

        ,

        fetchAll: crud.fetchAllWith(async () => {

            const [users, admins] = await Promise.all(oracle.isTenantUser() ? [calls.fetchAll(), calls.fetchReferences()] : [calls.fetchAll()])

            state.set(s => {

                s.all = users

                const usersbyId = utils().index(users).by(u => u.username)

                if (admins)
                    s.admins = admins.filter(a =>!usersbyId[a.username])

            })

            return users

        }).done()

        ,

        fetchOne: crud.fetchOneWith(async (username: string) => {

            const fetched = await calls.fetchOne(username)

            state.set(s => s.all = s.all.map(u => u.username === username ? fetched : u))

            return fetched

        }).done()

        ,

        saveQuietly

        ,

        saveManyQuietly

        ,

        push: (event: UserChangeEvent) => {

            const pushed = { ...event.user, fetched: true }

            state.set(s => {

                switch (event.type) {

                    case 'add': s.all = [...self.all(), pushed]; break;
                    case 'remove': s.all = s.all.filter(u => u.username !== pushed.username); break;
                    case 'change': s.all = s.all.map(u => u.username === pushed.username ? pushed : u); break;

                }

            })


        }

        ,

        save: crud.saveOneWith(saveQuietly).done()

        ,

        updateAccountData: async (user: User, account: Account) => {

            if (account.state !== user.details.preferences.accountState || account.id !== user.details.preferences.accountId)
                saveQuietly({ ...user, details: { ...user.details, preferences: { ...user.details.preferences, accountState: account.state, accountId: account.id } } })
        }



        ,

        removeWithConsent: crud.removeOneWith(async (user: User, onConfirm: () => void = () => { }, accountInContext?: Account) => {

            await internalRemove(user, accountInContext)

            state.set(s => s.all = s.all.filter(c => c.username !== user.username))

            onConfirm?.()

        })
            .withConsent({

                okChallenge: !mode.development && t('user.remove_challenge')

            })

        ,

        removeMany: crud.removeManyWith(async (list: User[], onConfirm?: () => void) => {

            if (!mode.development)
                throw Error("this is a dev-only operation")

            list.forEach(user => internalRemove(user)) // best effort

            const ids = list.map(t => t.username)

            state.set(s => s.all = s.all.filter(t => !ids.includes(t.username)))

            onConfirm?.()

        }).withConsent()  // dev-only operation

        ,

        removeTenantAndAllUsers: async (tenant: string, onConfirm?: () => void) => {

            const list = self.all().filter(u => u.tenant === tenant)

            const body = list.length > 0 ? t('tenant.remove_warn', { count: list.length }) : ''

            const askAndRemove = tenantcrud.removeOneWith(async (tenant: string) => {

                // remove tenant first
                await tenantstore.removeSilently(tenant)

                // if we've removed the tenant, best effort to remove user accounts individually, and clean state.
                // we don't need to remove users as these will cascade at the backend
                list.forEach(user => internalAccountRemove(user)) // best effort

                const ids = list.map(t => t.username)

                state.set(s => s.all = s.all.filter(t => !ids.includes(t.username)))

                onConfirm?.()

            }).withConsent({

                okChallenge: !mode.development && t('tenant.remove_challenge', { singular: t(tenantSingular).toUpperCase() }), body

            })

            askAndRemove(tenant)

        }

        ,

        saveTenantAndDeactivateAllUsers: async (current: Tenant, previous: Tenant) => {

            const usersInTenant = self.all().filter(u => u.tenant === current.id)

            const deactivate = current.lifecycle.state === 'inactive' && previous.lifecycle.state === 'active'

            const deactivateUsers = async (tenant: Tenant) => {

                if (deactivate) {
                    try {

                        await self.saveManyQuietly(usersInTenant.map(user => ({ ...user, lifecycle: { ...user.lifecycle, state: 'inactive' } })))
                            .catch(e => {

                                fb.showAndRethrow(e, t("tenant.deactivate.error"))

                            })


                        if (usersInTenant.length) {
                            await internalAccountSuspendMany(usersInTenant).catch(e => {
                                fb.showAndRethrow(e, t("tenant.deactivate_account.error"))
                            })
                        }

                        bus.publish(userLogoutTopic, { users: usersInTenant.map(u => u.username) })

                    } catch (tolerate) {

                        console.error("can't deactivate/suspend tenant users/accounts", tolerate)

                    }
                }

                return tenant
            }

            // const saveTenant = tenantcrud.saveOneWith(async () => {

            //     const saved = await tenantstore.save(current)

            //     if (deactivate) {

            //         try {

            //             await self.saveManyQuietly(usersInTenant.map(user => ({ ...user, lifecycle: { ...user.lifecycle, state: 'inactive' } })))
            //                 .catch(e => {

            //                     fb.showAndRethrow(e, t("tenant.deactivate.error"))

            //                 })


            //             if (usersInTenant.length) {
            //                 await internalAccountSuspendMany(usersInTenant).catch(e => {
            //                     fb.showAndRethrow(e, t("tenant.deactivate_account.error"))
            //                 })
            //             }

            //             bus.publish(userLogoutTopic, { users: usersInTenant.map(u => u.username) })

            //         } catch (tolerate) {

            //             console.error("can't deactivate/suspend tenant users/accounts", tolerate)

            //         }
                        
            //     }

            //     return saved

            // }).done()

            // const savedTenant = await saveTenant()

            const savedTenant = await tenantstore.save(current).then(deactivateUsers)

            return savedTenant

        }
    }

    return self

}