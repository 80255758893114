import { useT } from 'apprise-frontend-core/intl/language'
import { useSettings } from 'apprise-frontend-streams/settings/api'
import { SettingRenderProps, SettingsModule } from 'apprise-frontend-streams/settings/model'
import { Form } from 'apprise-ui/form/form'
import { SwitchBox } from 'apprise-ui/switchbox/switchbox'
import { useLogged } from './login/logged'
import { UserIconFull } from './user/constants'
import { UserMultiBox } from './user/userbox'
import { useMode } from 'apprise-frontend-core/config/api'
import { useContext } from 'react'
import { ToggleContext } from 'apprise-frontend-core/toggle/context'


export type IAMSettings = {

    betaTesters?: string[]
    disableTenantRemoval: boolean
    retrieveAllUserReferences: boolean

}



export const useIAMSettings = () => useSettings<IAMSettings>(iamType)


// A toggle activation policy that enables all features in production for a selected number of beta testers.
// If there are no beta-testers the features are enabled in staging and development.
// with this 'pass-through` we setup no beta-testers to see all features using any user.
// but it still allows for testing the feature toggle itself: we configure some users and see the feature disappear for others. 
export const useIsToggleActiveForBetaTester = (toggle: string) => {

    const mode = useMode()

    const logged = useLogged()

    const { betaTesters } = useIAMSettings()

    const { toggles } = useContext(ToggleContext).get()

    // extends default opt-out policy: what's mentioned is off.
    if (!toggles.includes(toggle) )
        return true

    return betaTesters?.length ? betaTesters.includes(logged.username) : !mode.production
}


export const IAMSettingsPanel = (props: SettingRenderProps<IAMSettings>) => {

    const { onChange, settings } = props

    const fields = useIAMSettingsFields()

    return <Form>

        <UserMultiBox info={fields.betaTesters} onChange={betaTesters => onChange({ ...settings, betaTesters })} >
            {settings.betaTesters}
        </UserMultiBox>

        <SwitchBox info={fields.disableRemoval} onChange={disableRemoval => onChange({ ...settings, disableTenantRemoval: !!disableRemoval })} >
            {settings.disableTenantRemoval}
        </SwitchBox>

        <SwitchBox info={fields.retrieveAllUserReferences} onChange={retrieveAllUserReferences => onChange({ ...settings, retrieveAllUserReferences: !!retrieveAllUserReferences })} >
            {settings.retrieveAllUserReferences}
        </SwitchBox>

    </Form>
}


const useIAMSettingsFields = () => {

    const t = useT()

    return {

        betaTesters: {

            label: t('user.betatester_lbl'),
            msg: t('user.betatester_msg'),
            help: t('user.betatester_help')
        }
        ,

        disableRemoval: {

            label: t('tenant.disable_removal_lbl'),
            msg: t('tenant.disable_removal_msg'),
            help: t('tenant.disable_removal_help')
        }



        ,

        retrieveAllUserReferences: {

            label: t('tenant.retrieve_allrefs_lbl'),
            msg: t('tenant.retrieve_allrefs_msg'),
            help: t('tenant.retrieve_allrefs_help')
        }

    }
}


export const iamType = "iam"

export const useIAMSettingsModule = () : SettingsModule<IAMSettings> => {

   
    return {

        Icon: UserIconFull,
        name: "IAM",
        type: iamType,
        defaults: {
            disableTenantRemoval: false,
            retrieveAllUserReferences: false
        },
        Render: IAMSettingsPanel,
        fields: useIAMSettingsFields

    }

}
