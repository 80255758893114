import { useActions } from 'apprise-frontend-core/authz/action'
import { any } from 'apprise-frontend-core/authz/constants'
import { useLoggedOracle } from 'apprise-frontend-core/authz/oracle'
import { guest } from 'apprise-frontend-iam/login/constants'
import { useLogin } from 'apprise-frontend-iam/login/logged'
import { noTenant } from 'apprise-frontend-iam/tenant/model'
import { User } from 'apprise-frontend-iam/user/model'
import { tenantActions } from './oracle'





export type ActionScope = 'direct' | 'indirect'


// answers tenancy questions about some target user.
// the user may be provided immediately, if it's known from the context.
// or it may be provided lazily, using the api itself (cf. given()).
// if it isn't provided at all, the api fallbacks to the logged user.

export const useTenancyOracle = (user?:User) => {

    // provides access to the logged user as required 
    // (can't useLogged() directly, may be called before login, eg. by mockeries).
    const login = useLogin()

    const actions = useActions()
    const oracle = useLoggedOracle()

    // returns the api for a target user. invoked immediately with the input user, if it exists.
    // may also be called from the api itself (cf.given()), to target different users on demand.
    const apiFor = (user?: User) => {

        // used internally to abstract over the target user, 
        // so that it can access the logged user lazily as a fallback.
        const current = () => user ?? login.logged()


        const self = {

            user: current
            
            ,


            // re-targets the api to another user (eg. use in loops over users).
            given: apiFor
            
            ,


            // most basic tenancy question, avoids having to known the target user.
            tenant: () => current().tenant
            
            ,


            //  -------------------------------------------------------------------------------------------- tenancy profiles


            hasNoTenant: () => current().tenant === noTenant || !current().tenant   // cover unexpected case too: tenant undefined

            ,

            isCrossTenant: () => self.hasNoTenant() || self.isMultiManager()

            ,


            isTenantUser: () => !self.hasNoTenant()

            ,


            // manages its own tenant, directly.
            isTenantManager: () => self.isTenantUser() && self.isManagerOf(self.tenant(), 'direct')


            ,

            isRegularTenantUser: () => self.isTenantUser() && !self.managesSomeTenant()

            ,

            // has a tenant and operates only in that tenant (with management rights or less).
            isSingleTenantUser: () => self.isTenantUser() && !self.isMultiManager()


            ,

            // a tenant user that manages multiple tenants other than its own. 
            isMultiManager: () => self.isTenantUser() && self.managesMultipleTenants('direct')

            ,

            isGuest: () => current().username === guest

            ,

            //  ---------------------------------------------------------------------------------------------- tenancy-based privilege checks.


            /** manages a given tenant, either directly or indirectly (as an implication). */
            isManagerOf: (tenant: string, mode: ActionScope = 'indirect') => {

                if (!tenant || tenant === any)
                    return false       // corner case. there's no tenant to manage

                const manageIt = actions.specialise(tenantActions.manage, tenant)

                return mode === 'indirect' ?

                    oracle.can(manageIt, tenant)   // is implied (standard)

                    :
   
                  actions.isAny(manageIt, current().permissions)  // exists one that is exactly it.

            }

            ,



            /** manages at least one tenant, possibly his own, either directly or indirectly (as an implication). */
            managesSomeTenant: (mode: ActionScope = 'indirect') =>

                mode === 'indirect' ? oracle.canForSome(tenantActions.manage) : actions.matchAny(tenantActions.manage, current().permissions)

            ,


            /** manages at least one tenant other than his own (and possibly all). */
            managesMultipleTenants: (mode: ActionScope = 'indirect') =>

                // manages other tenants directly.
                actions.matchAnyOther(actions.specialise(tenantActions.manage, current().tenant), current().permissions)

                ||

                // or has no tenant and can manage them all, indirectly.
                (mode === 'indirect' && self.hasNoTenant() && oracle.can(tenantActions.manage))

            ,

            //** all the tenants directly managed. */
            managedTenants: () => current().permissions.filter(p => p.resource !== any && actions.isLike(p, tenantActions.manage)).map(a => a.resource)

        }

        return self


    }

    return apiFor(user)


}