import { MultiSelectProps, PolySelectBox, PolySelectProps, SingleSelectProps } from 'apprise-ui/selectbox/selectbox';
import { useMemo } from 'react';
import { NoUserLabel, UserLabel } from './label';
import { User } from './model';
import { useUserStore } from './store';



type UserBoxProps = Partial<{

    users: User[]
    tenant: string

}>

export const UserBox = (props: SingleSelectProps<string> & UserBoxProps) => <PolyUserBox {...props} />
export const UserMultiBox = (props: MultiSelectProps<string> & UserBoxProps) => <PolyUserBox multi {...props} />

export const PolyUserBox = (props: PolySelectProps<string> & UserBoxProps) => {

    const store = useUserStore()

    const { users=store.all(), tenant, ...rest } = props

    const options = useMemo(()=> {
        
        // tenant first
        const options = tenant ? store.all().filter(u=>u.tenant===tenant) : users
    
        return options.map(u=>u.username)

    // eslint-disable-next-line
    }, [tenant,users] )
    

    return <PolySelectBox key={`user-boxset`}
        options={options}
        render={u => <UserLabel bare user={u} /> }
        placeholder={<NoUserLabel />} placeholderAsOption 
        {...rest} />
}