import { useClientSession } from 'apprise-frontend-core/client/session';
import { useT } from 'apprise-frontend-core/intl/language';
import { useFeedback } from 'apprise-ui/utils/feedback';
import { useContext } from 'react';
import { userLoggedKey } from '../user/constants';
import { User } from '../user/model';
import { logoutBaseUrl } from './constants';
import { LoginContext } from './context';



export const useLogged = () => useLogin().logged()


export const useBetaTester = () => useLogged()?.username

export type LogoutProperties = Partial<{

    askConsent: boolean,
    useAuth: boolean
}>


export const useLogin = () => {

    const state = useContext(LoginContext)

    const t = useT()

    const feedback = useFeedback()
    const session = useClientSession()


    const self = {

        isGuestAllowed: () => state.get().allowGuest

        ,

        isAuthenticated: () => state.get().authenticated

        ,

        logged: () => state.get().logged

        ,

        logout: (props?: LogoutProperties) => {

            const { askConsent, useAuth = self.isAuthenticated() } = props ?? {}

            const logout = () => {

                if (useAuth)

                    window.location.href = logoutBaseUrl

                else {

                    self.setLastLogged(null!)
                    window.location.reload()
                }
            }

            if (askConsent) {

                feedback.ask({
                    title: t('user.login_title'),
                    body: t('user.login_message'),
                    okText: t('user.login_btn'),
                    noValediction: true,
                    noCancel: true,

                }).thenRun(logout)

            }

            else logout()
        }


        ,

        // store logged user and remember it for next reload if we don't have AuthN active.
        // (this supports testing with different users when AuthNs is off)
        login: (user: User) => {

            self.setLogged(user)

            if (!self.isAuthenticated())
                self.setLastLogged(user)
        }

        ,

        // remember user for next load and force load.
        switchTo: (user: User) => {
            // first deactivate persistance because store changes based on tennant.
            session.activatePersistence(false)
            self.setLastLogged(user)
            window.location.reload()
        }

        ,

        setLogged: (user: User) => {

            state.set(s => s.logged = user)


        }


        ,

        setLastLogged: (user: User) => sessionStorage.setItem(userLoggedKey, JSON.stringify(user))

        ,

        lastLogged: () => {

            const item = sessionStorage.getItem(userLoggedKey)

            return item ? JSON.parse(item) as User : undefined

        }

    }


    return self


}


