import { useDefaulConfig } from '#config';
import { VersionUpgradeObserver } from '#utils/upgradeobserver';
import { AnalyticsToggles } from 'apprise-frontend-analytics/toggles';
import { Application } from 'apprise-frontend-core/application';
import { Client } from 'apprise-frontend-core/client/client';
import { Preload } from 'apprise-frontend-core/client/preload';
import { Configuration } from 'apprise-frontend-core/config/configuration';
import { ConfigLoader } from 'apprise-frontend-core/config/loader';
import { Intl } from 'apprise-frontend-core/intl/intl';
import { vite } from 'apprise-frontend-core/utils/vitebridge';
import { Events } from 'apprise-frontend-events/events';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import { Login } from 'apprise-frontend-iam/login/login';
import { UserInterface } from 'apprise-ui/provider';
import { lazy } from 'react';
import './infosys.scss';

export const Infosys = () => {

    const config = useDefaulConfig()

    const toggles : AnalyticsToggles[] = [] //['dashboards']

    return <Application toggles={toggles} tool={vite}>
        <UserInterface>
            <Configuration config={config} >
                <Preload>
                    <Intl>
                        <Client>
                            <Login allowGuest>
                                <ConfigLoader>
                                    <Events debug>
                                        <VersionUpgradeObserver>
                                            <InfosysRouter />
                                        </VersionUpgradeObserver>
                                    </Events>
                                </ConfigLoader>
                            </Login>
                        </Client>
                    </Intl>
                </Preload>
            </Configuration>
        </UserInterface>
    </Application >

}


const InfosysGuest = lazy(() => import('./infosys-guest'))
const InfosysLogged = lazy(() => import('./infosys-logged'))


export const InfosysRouter = () => {

    const oracle = useTenancyOracle()

    const Infosys = oracle.isGuest() ? InfosysGuest : InfosysLogged

    return <Infosys />


}