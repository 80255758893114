import { fallbackStateOver, State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import React from 'react'
import { AppSettings, SettingsModule } from './model'



export type SettingsModuleState = {
    
    all: SettingsModule[] 

}
const initialSettingsModules:SettingsModuleState = {
    all: []

}
export const SettingsModuleContext = React.createContext<State<SettingsModuleState>>(fallbackStateOver(initialSettingsModules))

export type SettingsCacheState = { 
    all: AppSettings
}       
const initialSettingsCache: SettingsCacheState = {
    all:{}
}
export const SettingsCacheContext = React.createContext<State<SettingsCacheState>>(fallbackStateOver(initialSettingsCache))


export const SettingsProvider = (props: React.PropsWithChildren) => {

    return <StateProvider initialState={initialSettingsModules} context={SettingsModuleContext}>
        <StateProvider initialState={initialSettingsCache} context={SettingsCacheContext}>
            {props.children}
        </StateProvider>
    </StateProvider>
}