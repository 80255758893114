import { fallbackStateOver, State } from 'apprise-frontend-core/state/api'
import { LoginState } from './login'
import { createContext } from 'react'





export const initialLoginState: LoginState = {

    logged: undefined!,
    allowGuest: false,
    authenticated: false

}


export const LoginContext = createContext<State<LoginState>>(fallbackStateOver(initialLoginState))

