import { SubmissionRef } from '#submission/model'
import { utils } from 'apprise-frontend-core/utils/common'
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle'
import { TenantResource } from 'apprise-frontend-iam/tenant/model'
import { BytestreamRef } from 'apprise-frontend-streams/model'
import { TagReference } from 'apprise-frontend-tags/tag/model'

// a record of a financial operation.
export type Operation = TenantResource & {

    // unique and internal, opaque from a domain perspective. 
    id: OperationRef

    // acquired on publication, upon exiting submission scope.
    submission?: SubmissionRef

    reportingTarget?: string,

    // acquired after parsing, tracks origin to contextualise validation errors.
    resource?: BytestreamRef

    // tracks lifecycle events and their agency.
    lifecycle: OperationLifecycle

    // program-scoped code.
    cci: TagReference

    // unique domain identifier.
    operationId: string

    // free-form.
    description: string


    financial: {

        eligibleCost: number
        eligiblePublicCost: number

        eligibleExpenditure: number
        eligiblePublicExpenditure: number

        EMFAFSupport: number
        EMFAFEligibleExpenditure: number

        approvalDate: string
        finalPaymentDate: string | undefined

    }

    indicators: IndicatorData[]

    sector: TagReference

    objective: TagReference

    // comes from NUTS2/3 + fixed "TCX1"="Non UMS": too large and external to be a category.
    location: string

    beneficiary: {

        lead: TagReference
        code: string
        name: string
        type: TagReference
        gender: TagReference
        previouslySupported: TagReference
    }

    stats: {

        people: number
        partners: number
    }

    domain: TagReference

    state: TagReference

    interventionType: TagReference

    type: TagReference

    vessel?: Partial<{

        // optionally identify vessels related to the operation.
        cfrNumbers: string[]
        increaseGT: number
        fleetSegment: string

    }>

    relevance: {

        sscf: TagReference

        landingObligation: TagReference

        climateChange: TagReference

        nonDiscrimination: TagReference

        genderEquality: TagReference

        disability: TagReference

    }

    support: TagReference


}


export type OperationLifecycle = Lifecycle<OperationState>

export type OperationState =

    'draft'                     // initial state, typically after file parsing.

    | 'published'               // available for reporting.



export type OperationRef = string

export type Beneficiary = Operation['beneficiary']
export type VesselData = Operation['vessel']
export type RelevanceData = Operation['relevance']
export type FinancialData = Operation['financial']

export type IndicatorData = {

    indicator: TagReference
    baseline: number
    expected: number
    expost: any | undefined
}


export const useOperationUtils = () => {

    const self = {

        mintId: () => utils().mint('OP')
    }

    return self
}

export const useOperationModel = () => {

    const utils = useOperationUtils()

    const self = {

        ...utils
    }

    return self
}