
import { useT } from 'apprise-frontend-core/intl/language'
import { useL } from 'apprise-frontend-core/intl/multilang'
import { OneOrMore, Optional, utils } from 'apprise-frontend-core/utils/common'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { Tag, TagReference, unknownTag } from 'apprise-frontend-tags/tag/model'
import { Label, LabelProps, LabelRow, UnknownLabel } from 'apprise-ui/label/label'
import * as React from 'react'
import { TagLabelIcon, tagType } from '../constants'
import { useTagModules } from './modules'
import { useTagRouting } from './routing'
import { useTagStore } from './store'

export type TagLabelProps = Partial<LabelProps & {

    tag: Optional<TagReference | Tag>

    displayMode: 'name' | 'code' | 'full'
}>

export const TagLabel = (props: TagLabelProps) => {

    const { tag, displayMode = 'name', title: titleOverride, tip: tipOverride, unknown: unknownOverride, ...rest } = props

    const t = useT()
    const l = useL()

    const categories = useCategoryStore()
    const store = useTagStore()
    const router = useTagRouting()

    // have nothing, render nothing. or render unknown.
    if (!tag)
        return unknownOverride ? <UnknownLabel  {...props} /> : null

    // resolve reference, failure is modelled as unknown tag to pass-through next steps. 
    const resolved = typeof tag === 'string' ? store.safeLookupTag(tag) : tag

    const category = categories.safeLookupCategory(resolved.category)

    const inactive = resolved.lifecycle?.state === 'inactive' || category.lifecycle?.state === 'inactive'

    const route = router.tagDetailRoute(resolved?.id)

    const isProtected = resolved.guarded || category.guarded

    // unresolved reference: show it, unless clients overrides.

    const isUnknown =  resolved.id === unknownTag
    const unknown =isUnknown ? ( unknownOverride ?? (typeof tag === 'string' ? tag : tag.name ) ): unknownOverride

    const nameOrDesc = l(resolved.name) === resolved.code ? l(resolved.description) : l(resolved.name) 
   
    const defaultTitle = resolved.code ? 
                            displayMode === 'code' ? 
                                resolved.code 
                            : displayMode === 'full' ?  
                                `[${resolved.code}] ${nameOrDesc}` 
                            : resolved.name  
                        : resolved.name

    // resolved reference: show default title unless client overrides.
    const title = isUnknown ? undefined : titleOverride ?? defaultTitle
   
    const defaultTip = resolved.code ?
                        displayMode === 'code' ? 
                            nameOrDesc : 
                            displayMode==='full' ? 
                                resolved.description :   
                                (resolved.code ? 
                                    `[${resolved.code}] ${resolved.code === l(resolved.name) ? l(resolved.description) ?? '' : l(resolved.name) ?? ''}` : 
                                     l(resolved?.description) ?? '')
                        :  l(resolved?.description) ?? ''

    // if we have an unresolved and 
    const tip = isUnknown ?  t("ui.unknown")  : tipOverride ?? defaultTip

    return <Label readonly={isProtected} unknown={unknown} inactive={inactive} icon={<TagLabelIcon />} title={title} tip={tip} linkTarget={tagType} linkTo={route} {...rest} />
}

export type TagLabelsProps = TagLabelProps & Partial<{

    tags: Optional<OneOrMore<TagReference | Tag>>

    noTruncate: boolean

    render: (t:Tag) => JSX.Element 
}>


export const TagLabels = (props: TagLabelsProps) => {

    const store = useTagStore()

    const { mode = 'tag', tags: unordered= [], render, ...rest } = props

    if (!unordered)
        return null

    const unorderedTags = utils().arrayOf(unordered)

    // eslint-disable-next-line
    const taglist = React.useMemo(() => [...unorderedTags].map(t => typeof t === 'string' ? store.safeLookupTag(t) : t), [unorderedTags])

    const elements = taglist.map((tag, i) => render?.(tag) ??  <TagLabel mode={mode} key={i} tag={tag} {...rest} />)

    return <LabelRow {...rest}>
        {elements}
    </LabelRow>


}


export type TagTypeLabelProps = LabelProps & {

    type: string | undefined
}


export const TagTypeLabel = (props: TagTypeLabelProps) => {

    const modules = useTagModules()
    const t = useT()

    const module = props.type ? modules.lookup(props.type) : undefined

    if (!module)
        return <UnknownLabel />

    const { icon = module.icon, title = t(module.singular), ...rest } = props


    return <Label icon={icon} title={title} {...rest} />
}